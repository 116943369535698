import { Cushion, Grid, MaxWidth, Panel, ScrollBox } from '@pitchero/react-ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import NoBackground from '../../club-frame/header/no-background';
import SeoHead from '../../seo/index';
import CLUB_PROP_TYPES from '../../../lib/prop-types/club';
import InfoSubNav from '../info-sub-nav';
import Sponsor from './sponsor';

const SponsorsList = ({ club, sponsors }) => {
  const [selectedSponsor, setSelectedSponsor] = useState(null);
  return (
    <>
      <SeoHead
        club={club}
        route="information.sponsors"
        titleTranslationKey="information:sponsors_title"
      />
      <NoBackground />
      <InfoSubNav sectionName="Sponsors" sectionTransKey="information:sponsors_title" />
      <Cushion horizontal="medium" vertical="medium">
        <MaxWidth component="div" maxWidth={980} center>
          <Grid
            className="ie-grid-three-col"
            columns="repeat(auto-fill, minmax(260px, 1fr))"
            columnGap="large"
            rowGap="medium"
          >
            {sponsors.map((sponsor) => (
              <Sponsor
                key={sponsor.id}
                sponsor={sponsor}
                onReadMore={() => setSelectedSponsor(sponsor)}
              />
            ))}
          </Grid>
        </MaxWidth>
      </Cushion>
      <Panel isOpen={!!selectedSponsor} onClose={() => setSelectedSponsor(null)}>
        <ScrollBox yOnly component="div">
          {selectedSponsor && <Sponsor inPanel sponsor={selectedSponsor} />}
        </ScrollBox>
      </Panel>
    </>
  );
};

SponsorsList.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
  sponsors: PropTypes.arrayOf(
    PropTypes.shape({
      blurb: PropTypes.string,
      image: PropTypes.string,
      subtitle: PropTypes.string,
      title: PropTypes.string,
    }),
  ).isRequired,
};

export default SponsorsList;
