import React from 'react';
import PropTypes from 'prop-types';
import { get, find } from 'lodash';
import { Cushion, MaxWidth } from '@pitchero/react-ui';
import Club404 from '../errors/club-404';
import SeoHead from '../seo';
import CLUB_PROP_TYPES from '../../lib/prop-types/club';
import { DOCUMENTS_PROPTYPES } from '../../lib/prop-types/documents';
import InformationArticle from './information-article';
import TwoPaneFrame from './two-pane-frame';
import InformationBreadcrumb from './information-breadcrumb';

const InformationArticles = ({ article, club, pageNumber = 1 }) => {
  const page = find(get(article, 'pages', []), { pageNumber });

  if (!page) {
    return <Club404 club={club} />;
  }

  if (article.pages.length === 1) {
    return (
      <>
        <SeoHead
          club={club}
          description={page.content}
          route="information.article"
          routeParams={{
            _name: article.name,
            articleId: article.id,
          }}
          title={article.name}
        />
        <InformationBreadcrumb title={article.name} />
        <Cushion
          vertical="medium"
          horizontal="medium"
          responsive={[{ maxWidth: 'tab', props: { horizontal: 'small' } }]}
          component="div"
        >
          <MaxWidth component="div" maxWidth={980} center>
            <InformationArticle
              title={article.name}
              club={club}
              imageSrc={page.image}
              noImage={!page.image}
              imageAlt={article.name}
              documents={page.documents}
            >
              <div className="bbcode-content" dangerouslySetInnerHTML={{ __html: page.content }} />
            </InformationArticle>
          </MaxWidth>
        </Cushion>
      </>
    );
  }

  return (
    <>
      <SeoHead
        club={club}
        description={page.content}
        route="information.article"
        routeParams={{
          _name: article.name,
          articleId: article.id,
          page: pageNumber,
        }}
        title={page.title}
      />
      <InformationBreadcrumb title={article.name} />
      <TwoPaneFrame
        title={article.name}
        openPanelLabel="information:article_open_panel"
        navItems={article.pages.map(({ pageNumber: itemPageNo, title }) => ({
          id: itemPageNo,
          label: title,
          isActive: itemPageNo === pageNumber,
          route: 'information.article',
          routeParams: {
            articleId: article.id,
            _name: article.name,
            page: itemPageNo,
          },
        }))}
      >
        <InformationArticle
          article={article}
          title={page.title}
          club={club}
          imageSrc={page.image}
          imageAlt={page.title}
          pageNumber={pageNumber}
          documents={page.documents}
        >
          <div className="bbcode-content" dangerouslySetInnerHTML={{ __html: page.content }} />
        </InformationArticle>
      </TwoPaneFrame>
    </>
  );
};

InformationArticles.defaultProps = {
  article: null,
  pageNumber: 1,
};

InformationArticles.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
  article: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    pages: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        content: PropTypes.string,
        image: PropTypes.string,
        title: PropTypes.string,
        documents: DOCUMENTS_PROPTYPES,
      }),
    ),
  }),
  pageNumber: PropTypes.number,
};

export default InformationArticles;
