import { Cushion, Grid, MaxWidth, Typography } from '@pitchero/react-ui';
import PropTypes from 'prop-types';
import React from 'react';
import Article from '../article';
import NoBackground from '../club-frame/header/no-background';
import SeoHead from '../seo';
import { withTranslation } from '../../lib/i18n';
import CLUB_PROP_TYPES from '../../lib/prop-types/club';
import InfoSubNav from './info-sub-nav';

const InformationLinks = ({ linkGroups, t, club }) => (
  <>
    <SeoHead club={club} route="information.links" titleTranslationKey="information:links_title" />
    <NoBackground />
    <InfoSubNav sectionName="Links" sectionTransKey="information:links_title" />
    <Cushion
      vertical="medium"
      horizontal="small"
      responsive={[{ minWidth: 'tab', props: { horizontal: 'medium', vertical: 'xlarge' } }]}
      component="div"
    >
      <MaxWidth component="div" maxWidth={980} center>
        <Article
          title={t('information:links_title')}
          club={club}
          noImage
          style={{ padding: '20px', boxSizing: 'border-box' }}
        >
          {linkGroups.map((group, index) => (
            <Cushion top={index === 0 ? 0 : 'large'} key={`group${group.id}`}>
              <Cushion bottom="medium">
                <Typography component="h2" preset="tab--active">
                  {group.title}
                </Typography>
              </Cushion>
              <Grid
                className="ie-grid-two-col"
                columns="1fr"
                responsive={[{ minWidth: 'lap', props: { columns: '1fr 1fr' } }]}
                columnGap="large"
                rowGap="medium"
              >
                {group.links.map((link) => (
                  <div key={`${group.id}-${link.id}`}>
                    <a href={link.url}>
                      <Typography preset="subtitle--small" color="primary">
                        {link.title}
                      </Typography>
                    </a>
                    <br />
                    <a href={link.url}>
                      <Typography preset="body--small" color="dustygrey">
                        {link.url}
                      </Typography>
                    </a>
                  </div>
                ))}
              </Grid>
            </Cushion>
          ))}
        </Article>
      </MaxWidth>
    </Cushion>
  </>
);

InformationLinks.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
  linkGroups: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          url: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation(['common', 'information'])(InformationLinks);
