import { Grid, Rectangle, withTheme } from '@pitchero/react-ui';
import { find, head } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import NoBackground from '../club-frame/header/no-background';
import Document from '../document';
import Club404 from '../errors/club-404';
import SeoHead from '../seo';
import { Trans } from '../../lib/i18n';
import CLUB_PROP_TYPES from '../../lib/prop-types/club';
import InfoSubNav from './info-sub-nav';
import TwoPaneFrame from './two-pane-frame';

const InformationDocuments = ({ club, documentGroups, groupId }) => {
  let displayedGroup;
  if (groupId) {
    displayedGroup = find(documentGroups, { id: groupId });
  } else {
    displayedGroup = head(documentGroups);
  }

  if (!displayedGroup) {
    return <Club404 club={club} />;
  }

  return (
    <>
      <SeoHead
        club={club}
        route="information.documents"
        routeParams={{
          group_id: groupId,
        }}
        titleTranslationKey="information:documents_page_title"
        translationValues={{
          groupName: displayedGroup.name,
        }}
      />
      <NoBackground />
      <InfoSubNav sectionName="Documents" sectionTransKey="information:documents_title" />
      <TwoPaneFrame
        title={<Trans i18nKey="information:documents_title">Documents</Trans>}
        openPanelLabel="information:documents_open_panel"
        navItems={documentGroups.map(({ id, name }) => ({
          id,
          route: 'information.documents',
          routeParams: { group_id: id },
          label: name,
          isActive: id === groupId,
        }))}
      >
        <Grid className="ie-grid-three-col" preset="documents">
          {displayedGroup.documents.map((clubDocument) => (
            <Rectangle key={clubDocument.id} borderBottom="thinMercury">
              <Document document={clubDocument} />
            </Rectangle>
          ))}
        </Grid>
      </TwoPaneFrame>
    </>
  );
};

InformationDocuments.defaultProps = {
  groupId: null,
};

InformationDocuments.propTypes = {
  groupId: PropTypes.number,
  club: CLUB_PROP_TYPES.isRequired,
  documentGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      documents: PropTypes.arrayOf(
        PropTypes.shape({
          fileSize: PropTypes.string,
          fileType: PropTypes.string,
          title: PropTypes.string,
          private: PropTypes.bool,
          url: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
};

export default withTheme(InformationDocuments);
