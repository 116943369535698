import React from 'react';
import PropTypes from 'prop-types';
import { Cushion, Shimmer, ShimmerImage, ShimmerTypography, Space } from '@pitchero/react-ui';

const ArticleLoadingPlaceHolder = ({ noImage }) => (
  <div>
    {!noImage && <ShimmerImage preset="headline" style={{ borderRadius: '4px 4px 0 0' }} />}

    <Cushion vertical="xlarge" horizontal="xlarge">
      <ShimmerTypography preset="title" width="40%" />
      <Space vertical="large">
        <ShimmerTypography preset="subtitle--small" width="20%" />
      </Space>

      <ShimmerTypography preset="subtitle--large" width="30%" />

      <Space vertical="large">
        <Shimmer width={50} height={8} />
      </Space>
      <ShimmerTypography preset="body--large" width="100%" />
      <ShimmerTypography preset="body--large" width="100%" />
      <ShimmerTypography preset="body--large" width="100%" />
      <ShimmerTypography preset="body--large" width="80%" />
    </Cushion>
  </div>
);

ArticleLoadingPlaceHolder.defaultProps = {
  noImage: false,
};

ArticleLoadingPlaceHolder.propTypes = {
  noImage: PropTypes.bool,
};

export default ArticleLoadingPlaceHolder;
