import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import {
  Cushion,
  Grid,
  Hide,
  MaxWidth,
  Rectangle,
  Shadow,
  Space,
  Typography,
} from '@pitchero/react-ui';
import { DOCUMENTS_PROPTYPES } from '../../lib/prop-types/documents';
import ArticleImage from '../article/image';
// import TopBannerVideoPlayerSwitch from '../adverts/top-banner-video-player-switch';
import RosBottomBanner from '../adverts/ros-bottom-banner';
import RosTopBanner from '../adverts/ros-top-banner';
import Document from '../document';

const PLACEHOLDER_IMAGE = 'https://img-res.pitchero.com/?url=images.pitchero.com%2Fup%2Fspacer.gif';

const InformationArticle = ({
  article,
  // club,
  title,
  imageAlt,
  imageSrc,
  noImage,
  pageNumber,
  children,
  documents,
}) => {
  // const { advertisingTier, sportId } = club;
  const articleTitle = pageNumber ? `${pageNumber}. ${title}` : title;

  return (
    <>
      <Cushion bottom="medium">
        <RosTopBanner />
        {/* <TopBannerVideoPlayerSwitch
          advertisingTier={advertisingTier}
          sportId={sportId}
          pageType="information.article"
        /> */}
      </Cushion>
      <Shadow>
        <Rectangle fill="#FFF" radius="rounded" component="div" style={{ width: '100%' }}>
          {!noImage && imageSrc && <ArticleImage src={imageSrc} alt={imageAlt} key={imageSrc} />}

          <Cushion vertical="xlarge" horizontal="xlarge">
            <MaxWidth component="div" maxWidth={660} center>
              {article && (
                <Space bottom="small">
                  <Typography preset="tag--large" color="dustygrey" component="div">
                    {`${article.name} ${pageNumber} of ${article.pages.length} `}
                  </Typography>
                </Space>
              )}

              <Typography component="h1" preset="title--large">
                {articleTitle}
              </Typography>

              <Hide maxWidth="tab">
                <Space
                  top="medium"
                  bottom="large"
                  responsive={[{ maxWidth: 'tab', props: { bottom: 'medium' } }]}
                >
                  <Rectangle
                    fill="primary"
                    style={{ height: 4, maxWidth: 36, display: 'block', marginInlineStart: 0 }}
                    component="hr"
                  />
                </Space>
              </Hide>

              <Typography
                component="div"
                preset="body--large"
                color="black"
                className="bbcode-content"
              >
                {children}
              </Typography>
              {!isEmpty(documents) && (
                <Cushion horizontal="small">
                  <Rectangle fill="seashellgrey" radius="rounded">
                    <Grid className="ie-grid-three-col" preset="documents">
                      {documents.map((document) => (
                        <Cushion key={document.id} horizontal="small">
                          <Document document={document} />
                        </Cushion>
                      ))}
                    </Grid>
                  </Rectangle>
                </Cushion>
              )}
            </MaxWidth>
          </Cushion>
        </Rectangle>
      </Shadow>
      <Cushion top="medium">
        <RosBottomBanner />
      </Cushion>
    </>
  );
};

InformationArticle.defaultProps = {
  article: null,
  imageAlt: '',
  imageSrc: PLACEHOLDER_IMAGE,
  noImage: false,
  pageNumber: null,
  documents: null,
};

InformationArticle.propTypes = {
  article: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    pages: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        content: PropTypes.string,
        image: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
  }),
  club: PropTypes.shape({
    advertisingTier: PropTypes.number.isRequired,
    sportId: PropTypes.number.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  imageAlt: PropTypes.string,
  imageSrc: PropTypes.string,
  noImage: PropTypes.bool,
  pageNumber: PropTypes.number,
  title: PropTypes.string.isRequired,
  documents: DOCUMENTS_PROPTYPES,
};

export default InformationArticle;
