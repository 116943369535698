import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Cushion,
  Grid,
  Hide,
  MaxWidth,
  Panel,
  Rectangle,
  ScrollBox,
  Show,
  Typography,
  Sticky,
} from '@pitchero/react-ui';
import { Trans } from '../../lib/i18n';
import ContentsNav from './contents-nav';

const TwoPaneFrame = ({ children, title, navItems, openPanelLabel }) => {
  const [contentsPanelOpen, setContentsPanelOpen] = useState(false);

  const renderSideNav = () => (
    <Sticky top={0}>
      <Cushion all="medium" responsive={[{ minWidth: 'desk', props: { all: 'xlarge' } }]}>
        <Typography preset="tab--inactive">{title}</Typography>
        <ContentsNav
          items={navItems.map((item) => ({
            ...item,
            onClick: () => {
              setContentsPanelOpen(false);
              if (item.onClick) {
                item.onClick();
              }
            },
          }))}
        />
      </Cushion>
    </Sticky>
  );

  return (
    <Grid
      className="two-pane-frame"
      columns="1fr"
      responsive={[
        { minWidth: 'tab', props: { columns: '280px 1fr' } },
        { minWidth: 'lap', props: { columns: '320px 1fr' } },
        { minWidth: 'desk', props: { columns: '400px 1fr' } },
      ]}
    >
      <Show minWidth="tab" style={{ msGridColumn: 1, msGridRow: 1 }}>
        <Rectangle fill="white" component="div">
          {renderSideNav()}
        </Rectangle>
      </Show>
      <Rectangle fill="seashellgrey" style={{ msGridColumn: 2, msGridRow: 1 }}>
        <ScrollBox yOnly component="div">
          <Hide minWidth="tab">
            <Cushion all="medium" component="div">
              <Button theme="primary" fullWidth onClick={() => setContentsPanelOpen(true)}>
                <Trans i18nKey={openPanelLabel}>{openPanelLabel}</Trans>
              </Button>
            </Cushion>
            <Panel
              isOpen={contentsPanelOpen}
              style={{ backgroundColor: 'white' }}
              onClose={() => setContentsPanelOpen(false)}
            >
              <ScrollBox yOnly component="div">
                {renderSideNav()}
              </ScrollBox>
            </Panel>
          </Hide>
          <Cushion
            horizontal="small"
            bottom="medium"
            responsive={[
              { minWidth: 'tab', props: { horizontal: 'medium', top: 'medium' } },
              { minWidth: 'lap', props: { horizontal: 'large', top: 'large', bottom: 'large' } },
            ]}
            component="div"
          >
            <MaxWidth component="div" maxWidth={980} center>
              {children}
            </MaxWidth>
          </Cushion>
          <Hide minWidth="tab">
            <Cushion all="medium" top="0" component="div">
              <Button theme="primary" fullWidth onClick={() => setContentsPanelOpen(true)}>
                <Trans i18nKey={openPanelLabel}>{openPanelLabel}</Trans>
              </Button>
            </Cushion>
          </Hide>
        </ScrollBox>
      </Rectangle>
    </Grid>
  );
};

TwoPaneFrame.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  openPanelLabel: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  navItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default TwoPaneFrame;
