import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CLUB_PROP_TYPES from '../../lib/prop-types/club';
import isServer from '../../lib/is-server';
import { fetchInformationPageData } from '../../store/information/actions';
import {
  hasLoadedPageData,
  isLoading,
  getArticle,
  getArticles,
  getDocumentGroups,
  getLinkGroups,
  getPolicies,
  getSponsors,
} from '../../store/information/selectors';
import Club404 from '../../components/errors/club-404';
import InformationArticles from '../../components/information/article';
import InformationDocuments from '../../components/information/documents';
import InformationHomepage from '../../components/information/homepage';
import InformationLinks from '../../components/information/links';
import InformationPolices from '../../components/information/policies';
import SponsorsList from '../../components/information/sponsors';

class InformationLandingPage extends React.Component {
  componentDidMount() {
    const { clubId, loading, loadData, hasLoaded } = this.props;
    if (!loading && !hasLoaded) {
      loadData(clubId);
    }
  }

  render() {
    const { club, loading, routeName, linksEnabled, documentsEnabled, sponsorsEnabled } =
      this.props;

    if (routeName === 'article') {
      const { article, pageNumber } = this.props;
      return <InformationArticles article={article} club={club} pageNumber={pageNumber} />;
    }

    if (routeName === 'links') {
      if (!linksEnabled) {
        return <Club404 club={club} />;
      }
      const { linkGroups } = this.props;
      return <InformationLinks linkGroups={linkGroups} club={club} />;
    }

    if (routeName === 'sponsors') {
      if (!sponsorsEnabled) {
        return <Club404 club={club} />;
      }
      const { sponsors } = this.props;
      return <SponsorsList sponsors={sponsors} club={club} />;
    }

    if (routeName === 'documents') {
      if (!documentsEnabled) {
        return <Club404 club={club} />;
      }
      const { documentGroups, groupId } = this.props;
      return <InformationDocuments documentGroups={documentGroups} groupId={groupId} club={club} />;
    }
    if (routeName === 'policies') {
      const { policies } = this.props;
      return <InformationPolices policies={policies} club={club} isLoading={loading} />;
    }

    const { articles, documentGroups, linkGroups, policies, sponsors } = this.props;
    return (
      <InformationHomepage
        articles={articles}
        documents={documentGroups}
        isLoading={loading}
        links={linkGroups}
        policies={policies}
        sponsors={sponsors}
        club={club}
        linksEnabled={linksEnabled}
        documentsEnabled={documentsEnabled}
        sponsorsEnabled={sponsorsEnabled}
      />
    );
  }
}

const getRouteNameFromPath = (path) => {
  const clubPrefixRegex = /^\/?clubs\/([a-zA-Z0-9\-_]+)\/(.*)/;
  const result = clubPrefixRegex.exec(path);
  const strippedPath = result ? result[2] : path;

  const policiesRegex = /^\/?policies/;
  if (policiesRegex.exec(strippedPath)) {
    return 'policies';
  }

  const articleRegex = /^\/?a\/([a-z0-9-]+)-([0-9]+)\.html/;
  if (articleRegex.exec(strippedPath)) {
    return 'article';
  }

  const dynamicPageRegex = /^\/?d\/(links|sponsors|documents)\.html/;
  const dynamicPageRegexResult = dynamicPageRegex.exec(strippedPath);
  if (dynamicPageRegexResult) {
    return dynamicPageRegexResult[1];
  }

  return 'index';
};

InformationLandingPage.getInitialProps = async ({ club, reduxStore, asPath, req, res, query }) => {
  const { id: clubId, informationPagesEnabled } = club;
  const routeName = getRouteNameFromPath(asPath);
  const linksEnabled = informationPagesEnabled.includes('links');
  const documentsEnabled = informationPagesEnabled.includes('documents');
  const sponsorsEnabled = informationPagesEnabled.includes('sponsors');
  const { page: pageNumber, articleId, group_id: groupId } = query;
  const initialProps = {
    activeSection: 'information',
    articleId: parseInt(articleId, 10),
    clubId,
    routeName,
    groupId: groupId ? parseInt(groupId, 10) : 0,
    pageNumber: pageNumber ? parseInt(pageNumber, 10) : 1,
    namespacesRequired: ['common', 'information'],
    pageType: `information.${routeName}`,
    linksEnabled,
    documentsEnabled,
    sponsorsEnabled,
  };

  if (!isServer()) {
    return initialProps;
  }

  if (routeName === 'links' && !linksEnabled) {
    res.statusCode = 404;
    return initialProps;
  }

  if (routeName === 'documents' && !documentsEnabled) {
    res.statusCode = 404;
    return initialProps;
  }

  if (routeName === 'sponsors' && !sponsorsEnabled) {
    res.statusCode = 404;
    return initialProps;
  }

  try {
    const { error } = await reduxStore.dispatch(fetchInformationPageData(clubId, req));
    if (error && error.errorStatusCode && res) {
      res.statusCode = error.errorStatusCode;
    }

    return initialProps;
  } catch (err) {
    return initialProps;
  }
};

InformationLandingPage.defaultProps = {
  article: null,
  groupId: null,
  pageNumber: null,
};

const articlePropTypes = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  page: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      content: PropTypes.string,
      image: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
});

InformationLandingPage.propTypes = {
  article: articlePropTypes,
  articles: PropTypes.arrayOf(articlePropTypes).isRequired,
  club: CLUB_PROP_TYPES.isRequired,
  clubId: PropTypes.number.isRequired,
  documentGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      documents: PropTypes.arrayOf(
        PropTypes.shape({
          fileSize: PropTypes.string,
          fileType: PropTypes.string,
          title: PropTypes.string,
          private: PropTypes.bool,
          url: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  groupId: PropTypes.number,
  hasLoaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  linkGroups: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          url: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  policies: PropTypes.arrayOf(
    PropTypes.shape({
      policy: PropTypes.string,
      type: PropTypes.string,
    }),
  ).isRequired,
  sponsors: PropTypes.arrayOf(
    PropTypes.shape({
      blurb: PropTypes.string,
      image: PropTypes.string,
      subtitle: PropTypes.string,
      title: PropTypes.string,
    }),
  ).isRequired,
  loadData: PropTypes.func.isRequired,
  pageNumber: PropTypes.number,
  routeName: PropTypes.string.isRequired,
  linksEnabled: PropTypes.bool.isRequired,
  documentsEnabled: PropTypes.bool.isRequired,
  sponsorsEnabled: PropTypes.bool.isRequired,
};

const dispatchToProps = {
  loadData: fetchInformationPageData,
};

const mapStateToProps = (state, ownProps) => ({
  loading: isLoading(state, ownProps.clubId),
  hasLoaded: hasLoadedPageData(state, ownProps.clubId),
  articles: getArticles(state, ownProps.clubId),
  article: ownProps.articleId ? getArticle(state, ownProps.clubId, ownProps.articleId) : null,
  documentGroups: getDocumentGroups(state, ownProps.clubId),
  linkGroups: getLinkGroups(state, ownProps.clubId),
  policies: getPolicies(state, ownProps.clubId),
  sponsors: getSponsors(state, ownProps.clubId),
});

export default connect(mapStateToProps, dispatchToProps)(InformationLandingPage);
