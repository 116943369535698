import React from 'react';
import PropTypes from 'prop-types';
import { findIndex } from 'lodash';
import { Cushion, Rectangle, RelativeContainer, Space, Typography } from '@pitchero/react-ui';
import ClubLink from '../club-link';

const ContentsNav = ({ items }) => {
  const wrapWithLink = (item, label) => {
    if (item.href) {
      return (
        <a href={item.href} onClick={item.onClick} key={item.id}>
          {label}
        </a>
      );
    }

    if (item.route) {
      return (
        <ClubLink route={item.route} params={item.routeParams || {}} scroll={false} key={item.id}>
          <a
            role="button"
            style={{ cursor: 'pointer', outline: 'none' }}
            onClick={item.onClick}
            onKeyDown={item.onClick}
            tabIndex={-1}
          >
            {label}
          </a>
        </ClubLink>
      );
    }

    return (
      <span
        role="button"
        onClick={item.onClick}
        onKeyDown={item.onClick}
        tabIndex={-1}
        key={item.id}
      >
        {label}
      </span>
    );
  };

  const activeIndex = findIndex(items, { isActive: true });
  return (
    <RelativeContainer>
      <Rectangle
        fill="primary"
        radius={2}
        style={{
          width: 4,
          height: 24,
          position: 'absolute',
          transform: `translateY(${activeIndex * 42}px)`,
          transition: 'transform ease 0.2s',
          top: 12,
        }}
      />
      <Rectangle borderLeft="2px solid #D3D3D3">
        <Cushion vertical="small">
          <Typography
            preset="subtitle--small"
            color="dustygrey"
            component="ol"
            style={{
              marginLeft: 1,
            }}
          >
            {items.map((item) => (
              <Space bottom="small" key={item.id}>
                <Cushion vertical="xsmall" left="small">
                  <Typography
                    preset="subtitle--small"
                    color={item.isActive ? 'dustygrey' : 'black'}
                    component="li"
                    style={{
                      ':hover': {
                        color: '#999',
                      },
                      transition: 'color .2s ease',
                    }}
                  >
                    {wrapWithLink(item, item.label)}
                  </Typography>
                </Cushion>
              </Space>
            ))}
          </Typography>
        </Cushion>
      </Rectangle>
    </RelativeContainer>
  );
};

ContentsNav.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
      href: PropTypes.string,
      route: PropTypes.string,
      routeParams: PropTypes.shape(),
      onClick: PropTypes.func.isRequired,
    }),
  ).isRequired,
};

export default ContentsNav;
